import React, { useState, useEffect } from 'react';

const InfoBar = ({ text, messageVersion }) => {
    const [isVisible, setIsVisible] = useState(false); // Dialog je zpočátku skrytý
    const [isClosing, setIsClosing] = useState(false); // Animace zavření

    // Zkontroluj LocalStorage při prvním načtení stránky
    useEffect(() => {
        const storedVersion = localStorage.getItem('infoBarVersion');
        const isClosed = localStorage.getItem('infoBarClosed');

        // Pokud se verze změnila, nebo dialog nebyl zavřený, zobraz ho
        if (storedVersion !== messageVersion || isClosed !== 'true') {
            setIsVisible(true);
            localStorage.setItem('infoBarVersion', messageVersion); // Ulož novou verzi zprávy
            localStorage.removeItem('infoBarClosed'); // Resetuj stav zavření
        }
    }, [messageVersion]);

    const handleClose = () => {
        setIsClosing(true); // Spustí animaci zavření
        localStorage.setItem('infoBarClosed', 'true'); // Ulož do LocalStorage, že uživatel zavřel info bar
    };

    useEffect(() => {
        if (isClosing) {
            // Počkej na animaci, a pak skryj lištu
            const timer = setTimeout(() => {
                setIsVisible(false);
            }, 500); // 500ms pro animaci
            return () => clearTimeout(timer); // Vyčištění timeru
        }
    }, [isClosing]);

    if (!isVisible) {
        return null;
    }

    return (
        <div
            className={`bg-cyan-100 border-t-4 border-cyan-500 rounded-b text-teal-900 px-4 py-3 shadow-md transition-all duration-500 ease-in-out transform ${
                isClosing ? 'max-h-0 opacity-0 -translate-y-10' : 'max-h-screen opacity-100 translate-y-0'
            }`}
            style={{ overflow: 'hidden' }} // Skryje obsah při sbalování
            role="alert"
        >
            <div className="flex">
                <div className="py-1">
                    <svg
                        className="fill-current h-6 w-6 text-cyan-600 mr-4 mt-2"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                    >
                        <path
                            d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"
                        />
                    </svg>
                </div>
                <div>
                    <p className="font-bold">Akce pro rodiče plavající přes víkend</p>
                    <p className="text-sm">{text}</p>
                </div>
                <button
                    onClick={handleClose}
                    className="ml-auto text-cyan-600 hover:text-cyan-900 font-bold"
                    style={{ position: 'absolute', right: '20px', top: '10px' }}
                >
                    &#10005;
                </button>
            </div>
        </div>
    );
};

export default InfoBar;