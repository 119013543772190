import React, { useState, useRef } from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { motion, AnimatePresence } from 'framer-motion';
import 'react-datepicker/dist/react-datepicker.css';
import 'tailwindcss/tailwind.css';
import PaymentQRcode from "./PaymentQRcode";
import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';
import { Oval } from 'react-loader-spinner'
import DragCloseDrawer from "./DragCloseDrawer";
import swimRules from "../assets/SwimRules.pdf"
import ninjasRules from "../assets/NinjasRules.pdf"
import { ThreeDots } from  'react-loader-spinner'

const steps = [
    { id: 1, title: 'Základní údaje' },
    { id: 2, title: 'Volba kurzu' },
    { id: 3, title: 'Údaje o dítěti' },
    { id: 4, title: 'Kontrola přihlášky' },
    { id: 5, title: 'Platba' },
];

const locationsAndTimes = {
    'LittleSwim': {
        '6 měsíců až 1 rok': {
            locations: {
                'VSS - Čapkárna': {
                    'Pátek': {
                        timeSlots: ['10:00', '10:30', '11:00', '11:30', '12:00', '12:30'],
                        price: 3600 // Cena za každý časový slot
                    }
                },
                'Karviná': {}
            }
        },
        '1 až 3 roky': {
            locations: {
                'VSS - Čapkárna': {
                    'Pátek': {
                        timeSlots: ['10:00', '10:30', '11:00', '11:30', '12:00', '12:30'],
                        price: 3600 // Cena za každý časový slot
                    }
                },
                'AD Landek': {
                    'Středa': {
                        timeSlots: ['09:00', '09:30', '10:00', '10:30'],
                        price: 3600
                    },
                    'Neděle': {
                        timeSlots: ['09:00', '09:40'],
                        price: 500
                    }
                },
                'Karviná': {}
            }
        },
        '3 až 6 let': {
            locations: {
                'AD Landek': {
                    'Neděle': {
                        timeSlots: ['Bude upřesněno'],
                        price: 3600
                    }
                }
            }
        },
        'Vaničkové plavání': {
            locations: {
                'u Vás doma': {
                    'dle domluvy': {
                        timeSlots: ['dle domluvy'],
                        price: 700
                    }
                }
            }
        }
    },
    'LittleNinjas': {
        '6 měsíců až 1 rok': {
            locations: {
                'AD Landek': {
                    'První pondělí v měsící': {
                        timeSlots: ['8:15', '11:00'],
                        price: 275
                    }
                }
            }
        },
        '1 až 2 roky': {
            locations: {
                'AD Landek': {
                    'Pondělí': {
                        timeSlots: ['9:00', '9:40', '10:20'],
                        price: 2200
                    }
                }
            }
        },
        '2 až 3 roky': {
            locations: {
                'AD Landek': {
                    'Pondělí': {
                        timeSlots: ['9:00', '9:40', '10:20'],
                        price: 2200
                    }
                }
            }
        },
        '3 až 6 let': {
            locations: {
                'AD Landek': {
                    'Pondělí': {
                        timeSlots: ['9:00', '9:40', '10:20'],
                        price: 2200
                    }
                }
            }
        }
    }
};


/*const insuranceOptions = [
    { value: 'vzp', label: 'VZP', code: '111', fullName: 'Všeobecná zdravotní pojišťovna' },
    { value: 'vozp', label: 'VOZP', code: '201', fullName: 'Vojenská zdravotní pojišťovna' },
    { value: 'cpzp', label: 'ČPZP', code: '205', fullName: 'Česká průmyslová zdravotní pojišťovna' },
    { value: 'ozp', label: 'OZP', code: '207', fullName: 'Oborová zdravotní pojišťovna' },
    { value: 'zps', label: 'ZPS', code: '209', fullName: 'Zaměstnanecká pojišťovna Škoda' },
    { value: 'mv', label: 'ZPMV', code: '211', fullName: 'Ministerstvo vnitra' },
    { value: 'rbp', label: 'RBP', code: '213', fullName: 'RBP – Revírní bratrská pokladna' },
];*/

const minimumAges = {
    'Vaničkové plavání': { days: 6 * 7, title: '6 týdnů' },
    '6 měsíců až 1 rok': { days: 6 * 30, title: '6 měsíců' },
    '1 až 3 roky': { days: 1 * 365, title: '1 rok' },
    '3 až 6 let': { days: 3 * 365, title: '3 roky' },
    '1 až 2 roky': { days: 1 * 365, title: '1 rok' },
    '2 až 3 roky': { days: 2 * 365, title: '2 roky' },
};

const MultiStepForm = () => {
    const [captchaValue, setCaptchaValue] = useState('');
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({
        name: '',
        surname: '',
        email: '',
        phone: '',
        address: '',
        courseType: '',
        specificCourse: '',
        location: '',
        day: '',
        time: '',
        childName: '',
        childSurname: '',
        childBirthdate: new Date(),
        //insurance: '',
        healthStatus: '',
        anotherName: '',
        consent: false
    });
    const [errors, setErrors] = useState({});
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [vsNumber, setVsNumber] = useState(null);
    const [preorder, setPreorder] = useState(false);
    const [isSenior, setIsSenior] = useState(false);
    const [withAnother, setWithAnother] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const captchaRef = useRef(null)
    const [submitText, setSubmitText] = useState("Závazně odeslat")
    const [submitDisabled, setSubmitDisabled] = useState(false)

    const openSwimPdf = () => {
        window.open(swimRules, '_blank');
    };

    const openNinjasPdf = () => {
        window.open(ninjasRules, '_blank');
    };


    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const handleCaptchaChange = (value) => {
        //console.log("Captcha Value: ", value);
        setCaptchaValue(value);
    };

    const validatePhone = (phone) => {
        const re = /^\d+$/;
        return re.test(String(phone));
    };

    const validateStep = (currentStep) => {
        let newErrors = {};

        if (currentStep === 1) {
            if (!formData.name) newErrors.name = 'Jméno je povinné';
            if (!formData.surname) newErrors.surname = 'Příjmení je povinné';
            if (!formData.email) newErrors.email = 'Email je povinný';
            else if (!validateEmail(formData.email)) newErrors.email = 'Neplatný formát e-mailu';
            if (!formData.phone) newErrors.phone = 'Telefonní číslo je povinné';
            else if (!validatePhone(formData.phone)) newErrors.phone = 'Telefonní číslo může obsahovat pouze číslice';
            if (!formData.address) newErrors.address = 'Adresa je povinná';
        } else if (currentStep === 2) {
            if (!formData.courseType || !formData.specificCourse || !formData.location) {
                newErrors.course = 'Oou, něco je špatně... zkontrolujte údaje!';
            }
            if (
                !(formData.courseType === 'LittleSwim' &&
                    formData.specificCourse === 'Vaničkové plavání' &&
                    formData.location === 'u Vás doma') &&
                (!formData.day || !formData.time)
            ) {
                newErrors.course = 'Oou, něco je špatně... zkontrolujte údaje!';
            }
        } else if (currentStep === 3) {
            const now = new Date();
            const ageInDays = (now - new Date(formData.childBirthdate)) / (1000 * 60 * 60 * 24);
            const selectedCourse = formData.specificCourse;
            const minimumAge = minimumAges[selectedCourse].days || 0;
            const ageTitle = minimumAges[selectedCourse].title

            console.log(minimumAge)
            console.log(ageTitle)

            if (!formData.childName) newErrors.childName = 'Jméno dítěte je povinné';
            if (!formData.childSurname) newErrors.childSurname = 'Příjmení dítěte je povinné';
            if (!formData.childBirthdate) newErrors.childBirthdate = 'Datum narození dítěte je povinné';
            if (!preorder && ageInDays < minimumAge) {
                newErrors.childBirthdate = `Minimální věk dítěte pro tento kurz je ${ageTitle}.`;
            }
            //if (!formData.insurance) newErrors.insurance = 'Pojišťovna je povinná';
            if (!formData.healthStatus) newErrors.healthStatus = 'Zdravotní stav je povinný';
        } else if (currentStep === 4) {
            if (!formData.consent) newErrors.consent = 'Udělte souhlas se zpracováním osobních údajů';

        }

        return newErrors;
    };

    const generateVS = () => {
        var number = Math.floor(Math.random() * (9999999999 - 1000000000 + 1)) + 1000000000;
        setVsNumber(number)
        return number
    }

    const nextStep = () => {
        const stepErrors = validateStep(step);

        if (Object.keys(stepErrors).length > 0) {
            setErrors(stepErrors);
            setErrorMessage('Oou, něco je špatně... zkontrolujte údaje!');
            setShowError(true);
            return;
        }

        setErrors({});
        setShowError(false);
        setIsFormSubmitted(false);
        setStep(step + 1);
    };

    const prevStep = () => {
        setErrors({});
        setShowError(false);
        setStep(step - 1);
    };

    const handleChange = (input) => (e) => {
        setFormData({ ...formData, [input]: e.target.value });

        if (errors[input]) {
            setErrors((prevErrors) => {
                const newErrors = { ...prevErrors };
                delete newErrors[input];
                return newErrors;
            });
        }
    };

    const handleCourseSelect = (courseType) => {
        setFormData({ ...formData, courseType, specificCourse: '', location: '', day: '', time: '' });
        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            delete newErrors.course;
            return newErrors;
        });
    };

    const handleSpecificCourseSelect = (specificCourse) => {
        setFormData({ ...formData, specificCourse, location: '', day: '', time: '' });
        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            delete newErrors.course;
            return newErrors;
        });
    };

    const handleLocationSelect = (location) => {
        setFormData({ ...formData, location, day: '', time: '' });
        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            delete newErrors.course;
            return newErrors;
        });
    };

    const handleDaySelect = (day) => {
        setFormData({ ...formData, day, time: '' });
        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            delete newErrors.course;
            return newErrors;
        });
    };

    const handleTimeSelect = (time) => {
        setFormData({ ...formData, time });
        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            delete newErrors.course;
            return newErrors;
        });
    };

    const handleDateChange = (date) => {
        setFormData({ ...formData, childBirthdate: date });
        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            delete newErrors.childBirthdate;
            return newErrors;
        });
    };

    /*const handleInsuranceChange = (selectedOption) => {
        setFormData({ ...formData, insurance: selectedOption });
        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            delete newErrors.insurance;
            return newErrors;
        });
    };*/

    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Měsíce jsou 0-indexované
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsFormSubmitted(true);
        //setLoading(true);
        if (formData.consent)
        {
            // Spustí reCAPTCHA a získá hodnotu
            const recaptchaValue = await captchaRef.current.executeAsync();

            // Pokud reCAPTCHA projde, pokračujeme se zpracováním formuláře
            if (recaptchaValue) {
                console.log("reCAPTCHA prošla:", recaptchaValue);

                const number = generateVS();


                console.log("Je v poho ")
                setSubmitDisabled(true)
                setSubmitText(<ThreeDots
                    height="30"
                    width="30"
                    radius="9"
                    color="#fff"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName="text-align: center"
                    visible={true}
                /> )


                // Parametry pro EmailJS

                // Testovací server
                /*const serviceID = 'service_6fnv8w7';
                const templateID = 'template_0x3vtp2';
                const userID = 'ndxe6osmlsh-UKMiH';*/

                // Ostrý server
                /*const serviceID = 'service_axqzvln';
                const templateID = 'template_dc2kz3o';
                const userID = 'HMLp6QDC1GqGGaQqX';*/

                // noreplylittleclubostrava@gmail.com sekundarni v pripade vypadku nebo precerpani slotu
                const serviceID = 'service_dx079d9';
                const templateID = 'template_kntald4';
                const userID = 'iAa8GtJbaHjZS9z1K';

                //const domain = "http://localhost:3000";
                const domain = "https://littleclub.cz";

                const formDataObject = {
                    name: formData.name,
                    surname: formData.surname,
                    email: formData.email,
                    phone: formData.phone,
                    address: formData.address,
                    courseType: formData.courseType,
                    specificCourse: formData.specificCourse,
                    location: formData.location,
                    childName: formData.childName,
                    childSurname: formData.childSurname,
                    childBirthdate: formatDate(formData.childBirthdate),
                    //insurance: formData.insurance.fullName,
                    healthStatus: formData.healthStatus,
                    consent: formData.consent ? 'Ano' : 'Ne',
                    preorder: preorder ? 'Ano' : 'Ne',
                    senior: isSenior ? 'Ano' : 'Ne',
                    withAnother: withAnother ? 'Ano' : 'Ne',
                    anotherName: formData.anotherName,
                    customerID: number,
                    rulesLink: formData.courseType === "LittleSwim" ? `${domain}/static/media/SwimRules.95150c1f89aab4d7866a.pdf` : `${domain}/static/media/NinjasRules.1a37cfd33dfcbf14a1f8.pdf`,
                    account: formData.courseType === "LittleSwim" ? '1195384511/5500' : '7970238003/5500',
                    price: formData.specificCourse === "Vaničkové plavání" ? locationsAndTimes[formData.courseType][formData.specificCourse].locations[formData.location]['dle domluvy']?.price : locationsAndTimes[formData.courseType][formData.specificCourse].locations[formData.location]?.[formData.day]?.price,
                    'g-recaptcha-response': recaptchaValue
                };

                if (formData.specificCourse !== 'Vaničkové plavání') {
                    formDataObject.day = formData.day;
                    formDataObject.time = formData.time;
                }

                const stepErrors = validateStep(step);

                if (Object.keys(stepErrors).length > 0) {
                    setErrors(stepErrors);
                    setErrorMessage('Vyplňte všechny povinné údaje.');
                    setShowError(true);
                    return;
                }

                // Odeslání e-mailu pomocí EmailJS
                emailjs.send(serviceID, templateID, formDataObject, userID)
                    .then((result) => {
                        console.log("Sending status: " + result.text);
                        // Zpracování úspěchu
                        setIsFormSubmitted(true);
                        setErrors({});
                        setShowError(false);
                        setStep(step + 1);

                    }, (error) => {
                        console.log(error.text);
                        // Zpracování chyby
                        setErrors({});
                        setErrorMessage('Ověření Google Captcha neprošlo. Klikněte prosím znovu na Závazně odeslat');
                        setShowError(true);
                        setSubmitDisabled(false)
                        setSubmitText("Závazně odeslat")

                    });
            }
        }
            else {
                setErrorMessage('Prosím potvrďte souhlas se povinnýmí údaji.');
                setShowError(true);
            }


            // Kontrola existence captchaRef.current
            if (captchaRef.current) {
                console.log("Resetování reCAPTCHA");
                captchaRef.current.reset();
                //setLoading(false);
            } else {
                console.warn("captchaRef.current není definováno");
            }




    };


    const CourseSelection = () => (
        <div className="p-4">
            <h2 className="block text-gray-700 text-md font-varela mb-1 dark:text-white">Vyberte typ kurzu</h2>
            <div className="flex flex-wrap gap-4 mb-4 font-varela">
                <button
                    className={`px-4 py-2 border rounded-md flex-1 min-w-[150px] dark:bg-gray-900 dark:text-white dark:border-gray-900 ${formData.courseType === 'LittleSwim' ? 'bg-blue-800 text-white dark:bg-sky-600 font-bold dark:text-white dark:border-sky-600'  : ''}`}
                    onClick={() => handleCourseSelect('LittleSwim')}
                >
                    LittleSwim
                </button>
                <button
                    className={`px-4 py-2 border rounded-md flex-1 min-w-[150px] dark:bg-gray-900 dark:text-white dark:border-gray-900 ${formData.courseType === 'LittleNinjas' ? 'bg-yellow-500 text-black dark:bg-yellow-600 font-bold dark:text-black dark:border-yellow-600' : ''}`}
                    onClick={() => handleCourseSelect('LittleNinjas')}
                >
                    LittleNinjas
                </button>
            </div>
            {formData.courseType && (
                <>
                    <h3 className="block text-gray-700 text-md font-varela mb-1 dark:text-white">Vyberte lekci</h3>
                    <div className="flex flex-wrap gap-4 mb-4 font-varela">
                        {Object.keys(locationsAndTimes[formData.courseType]).map((course) => (
                            <button
                                key={course}
                                className={
                                            formData.courseType === 'LittleSwim' ? `px-4 py-2 border rounded-md flex-1 min-w-[150px] dark:bg-gray-900 dark:text-white dark:border-gray-900 ${formData.specificCourse === course ? 'bg-blue-800 text-white dark:bg-sky-600 font-bold dark:text-white dark:border-sky-600' : ''}`:
                                                                                   `px-4 py-2 border rounded-md flex-1 min-w-[150px] dark:bg-gray-900 dark:text-white dark:border-gray-900 ${formData.specificCourse === course ? 'bg-yellow-500 text-black dark:bg-yellow-600 font-bold dark:text-black dark:border-yellow-600' : ''}`
                                          }
                                onClick={() => handleSpecificCourseSelect(course)}
                            >
                                {course}
                            </button>
                        ))}
                    </div>
                    {formData.specificCourse && (
                        <>
                            <h3 className="block text-gray-700 text-md font-varela mb-1 dark:text-white">Vyberte místo</h3>
                            <div className="flex flex-wrap gap-4 mb-4 font-varela">
                                {Object.keys(locationsAndTimes[formData.courseType][formData.specificCourse].locations).map((location) => (
                                    <button
                                        key={location}
                                        className={
                                            formData.courseType === 'LittleSwim' ? `px-4 py-2 border rounded-md flex-1 min-w-[150px] dark:bg-gray-900 dark:text-white dark:border-gray-900 ${formData.location === location ? 'bg-blue-800 text-white dark:bg-sky-600 font-bold dark:text-white dark:border-sky-600' : ''}`:
                                                `px-4 py-2 border rounded-md flex-1 min-w-[150px] dark:bg-gray-900 dark:text-white dark:border-gray-900 ${formData.location === location ? 'bg-yellow-500 text-black dark:bg-yellow-600 font-bold dark:text-black dark:border-yellow-600' : ''}`
                                        }
                                        onClick={() => handleLocationSelect(location)}
                                    >
                                        {location}
                                    </button>
                                ))}
                            </div>
                            {formData.location && formData.specificCourse !== 'Vaničkové plavání' && (
                                <>
                                    <h3 className="block text-gray-700 text-md font-varela mb-1 dark:text-white">Vyberte den</h3>
                                    <div className="flex flex-wrap gap-4 mb-4 font-varela">
                                        {Object.keys(locationsAndTimes[formData.courseType][formData.specificCourse].locations[formData.location]).map((day) => (
                                            <button
                                                key={day}
                                                className={
                                                    formData.courseType === 'LittleSwim' ? `px-4 py-2 border rounded-md flex-1 min-w-[150px] dark:bg-gray-900 dark:text-white dark:border-gray-900 ${formData.day === day ? 'bg-blue-800 text-white dark:bg-sky-600 font-bold dark:text-white dark:border-sky-600' : ''}`:
                                                        `px-4 py-2 border rounded-md flex-1 min-w-[150px] dark:bg-gray-900 dark:text-white dark:border-gray-900 ${formData.day === day ? 'bg-yellow-500 text-black dark:bg-yellow-600 font-bold dark:text-black dark:border-yellow-600' : ''}`
                                                }
                                                onClick={() => handleDaySelect(day)}
                                            >
                                                {day}
                                            </button>
                                        ))}
                                    </div>
                                    {formData.day && (
                                        <>
                                            <h3 className="block text-gray-700 text-md font-varela mb-1 dark:text-white">Vyberte
                                                čas</h3>
                                            <div className="flex flex-wrap gap-4 mb-4 font-varela">
                                                {locationsAndTimes[formData.courseType][formData.specificCourse].locations[formData.location][formData.day].timeSlots.map((time) => (
                                                    <button
                                                        key={time}
                                                        className={
                                                            formData.courseType === 'LittleSwim' ? `px-4 py-2 border rounded-md flex-1 min-w-[150px] dark:bg-gray-900 dark:text-white dark:border-gray-900 ${formData.time === time ? 'bg-blue-800 text-white dark:bg-sky-600 font-bold dark:text-white dark:border-sky-600' : ''}` :
                                                                `px-4 py-2 border rounded-md flex-1 min-w-[150px] dark:bg-gray-900 dark:text-white dark:border-gray-900 ${formData.time === time ? 'bg-yellow-500 text-black dark:bg-yellow-600 font-bold dark:text-black dark:border-yellow-600' : ''}`
                                                        }
                                                        onClick={() => handleTimeSelect(time)}
                                                    >
                                                        {time}
                                                    </button>
                                                ))}
                                            </div>
                                            <p className="text-gray-500 dark:text-gray-400 mt-2 italic font-varela text-sm">
                                                Poznámka: Vybraný čas je pouze preferovaný a může se podle kapacity
                                                dodatečně změnit po telefonickém potvrzení.
                                            </p>
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </>
            )}
        </div>
    );




    const StepIndicator = () => (
        <div className="flex flex-col md:flex-row justify-between items-center mb-8">
            <div className="order-2 md:order-1 mt-4 md:mt-0">
                <h1 className="uppercase text-xl md:text-2xl text-center md:text-left font-varela dark:text-white">{steps[step - 1].title}</h1>
            </div>
            <div className="flex space-x-4 order-1 md:order-2">
                {steps.filter(s => s.id <= 4).map((s) => (
                    <div key={s.id}
                         className={`w-8 h-8 rounded-full flex items-center justify-center ${s.id <= step ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}>
                        {s.id}
                    </div>
                ))}
            </div>
        </div>
    );




    return (
        <div className="container mx-auto p-8">
            <form onSubmit={handleSubmit}  className="space-y-8">
                {step < 5 && (<StepIndicator/>)}
                <AnimatePresence>
                    {showError && (
                        <motion.div
                            initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -20 }} className="bg-red-500 text-center rounded-md text-white p-4 mb-2"
                        >
                            {errorMessage}
                        </motion.div>
                    )}
                </AnimatePresence>

                {step === 1 && (
                    <div>
                        <div className="mb-4">
                            <label
                                className="block text-gray-700 text-md font-varela mb-1 dark:text-white">Jméno</label>
                            <input
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange('name')}
                                className={`shadow appearance-none border rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline dark:bg-gray-900 dark:text-white dark:border-gray-900 ${errors.name ? 'border-red-500' : 'border-gray-300'}`}
                            />
                            {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
                        </div>
                        <div className="mb-4">
                            <label
                                className="block text-gray-700 text-md font-varela mb-1 dark:text-white">Příjmení</label>
                            <input
                                type="text"
                                name="surname"
                                value={formData.surname}
                                onChange={handleChange('surname')}
                                className={`shadow appearance-none border rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline dark:bg-gray-900 dark:text-white dark:border-gray-900 ${errors.surname ? 'border-red-500' : 'border-gray-300'}`}
                            />
                            {errors.surname && <p className="text-red-500 text-sm">{errors.surname}</p>}
                        </div>
                        <div className="mb-4">
                            <label
                                className="block text-gray-700 text-md font-varela mb-1 dark:text-white">Email</label>
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange('email')}
                                className={`shadow appearance-none border rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline dark:bg-gray-900 dark:text-white dark:border-gray-900 ${errors.email ? 'border-red-500' : 'border-gray-300'}`}
                            />
                            {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-md font-varela mb-1 dark:text-white">Telefonní
                                číslo</label>
                            <input
                                type="text"
                                name="mobile"
                                value={formData.phone}
                                onChange={handleChange('phone')}
                                className={`shadow appearance-none border rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline dark:bg-gray-900 dark:text-white dark:border-gray-900 ${errors.phone ? 'border-red-500' : 'border-gray-300'}`}
                            />
                            {errors.phone && <p className="text-red-500 text-sm">{errors.phone}</p>}
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-md font-varela mb-1 dark:text-white">Adresa</label>
                            <input
                                type="text"
                                name="address"
                                value={formData.address}
                                onChange={handleChange('address')}
                                className={`shadow appearance-none border rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline dark:bg-gray-900 dark:text-white dark:border-gray-900 ${errors.address ? 'border-red-500' : 'border-gray-300'}`}
                            />
                            {errors.address && <p className="text-red-500 text-sm">{errors.address}</p>}
                        </div>
                    </div>
                )}

                {step === 2 && <CourseSelection/>}

                {step === 3 && (
                    <div className="p-4 max-w-3xl mx-auto space-y-4">
                        <div className="mb-4">
                            <label className="block text-gray-700 text-md font-varela mb-1 dark:text-white">Jméno
                                dítěte</label>
                            <input
                                type="text"
                                name="nameChild"
                                value={formData.childName}
                                onChange={handleChange('childName')}
                                className={`w-full px-4 py-2 font-varela border rounded-md text-gray-900 leading-tight focus:outline-none focus:shadow-outline dark:bg-gray-900 dark:text-white dark:border-gray-900 ${errors.childName ? 'border-red-500' : 'border-gray-300'} transition duration-300 ease-in-out`}
                            />
                            {errors.childName && <p className="text-red-500 text-sm mt-1">{errors.childName}</p>}
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-md font-varela mb-1 dark:text-white">Příjmení
                                dítěte</label>
                            <input
                                type="text"
                                name="surnameChild"
                                value={formData.childSurname}
                                onChange={handleChange('childSurname')}
                                className={`w-full px-4 py-2 font-varela border rounded-md text-gray-900 leading-tight focus:outline-none focus:shadow-outline dark:bg-gray-900 dark:text-white dark:border-gray-900  ${errors.childSurname ? 'border-red-500' : 'border-gray-300'} transition duration-300 ease-in-out`}
                            />
                            {errors.childSurname && <p className="text-red-500 text-sm mt-1">{errors.childSurname}</p>}
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-md font-varela mb-1 dark:text-white">Datum
                                narození
                                dítěte</label>
                            <DatePicker
                                selected={formData.childBirthdate}
                                onChange={handleDateChange}
                                dateFormat="dd/MM/yyyy"
                                name="birth"
                                className={`w-full px-4 py-2 font-varela border rounded-md text-gray-900 leading-tight focus:outline-none focus:shadow-outline dark:bg-gray-900 dark:text-white dark:border-gray-900  ${errors.childBirthdate ? 'border-red-500' : 'border-gray-300'} transition duration-300 ease-in-out`}
                            />
                            {errors.childBirthdate &&
                                <p className="text-red-500 text-sm mt-1">{errors.childBirthdate}</p>}
                        </div>
                        {/*<div className="mb-4">
                            <label
                                className="block text-gray-700 text-md font-varela mb-1 dark:text-white">Pojišťovna</label>
                            <Select
                                options={insuranceOptions}
                                value={formData.insurance}
                                name="insurance"
                                onChange={handleInsuranceChange}
                                formatOptionLabel={({label, code, fullName}) => (
                                    <div className="flex items-center">
                                        <span className="hidden md:inline">{fullName}</span>
                                        <span className="inline md:hidden">{label}</span>
                                        <span className="pl-1 text-gray-500">({code})</span>
                                    </div>
                                )}
                                className={`w-full border rounded-md text-gray-900 focus:ring-blue-500 focus:border-blue-500 ${errors.insurance ? 'border-red-500' : 'border-gray-300'} transition duration-300 ease-in-out`}
                            />
                            {errors.insurance && <p className="text-red-500 text-sm mt-1">{errors.insurance}</p>}
                        </div>*/}
                        <div className="mb-4">
                            <label className="block text-gray-700 text-md font-varela mb-1 dark:text-white">Zdravotní
                                stav
                                dítěte</label>
                            <textarea
                                name="healthStatus"
                                value={formData.healthStatus}
                                onChange={handleChange('healthStatus')}
                                className={`w-full px-4 py-2 font-varela border rounded-md text-gray-900 leading-tight focus:outline-none focus:shadow-outline dark:bg-gray-900 dark:text-white dark:border-gray-900  ${errors.healthStatus ? 'border-red-500' : 'border-gray-300'} transition duration-300 ease-in-out`}
                            ></textarea>
                            {errors.healthStatus && <p className="text-red-500 text-sm mt-1">{errors.healthStatus}</p>}
                        </div>
                        <div className="mb-4">
                            <div className="flex items-center mb-4">
                                <input
                                    type="checkbox"
                                    checked={isSenior}
                                    onChange={() => setIsSenior(!isSenior)}
                                    className="form-checkbox h-5 w-5 text-blue-600"
                                />
                                <label className="ml-2 text-gray-700 text-md font-varela dark:text-white">Absolvovalo
                                    dítě v minulosti podobný kurz</label>
                            </div>
                            <div className="flex items-center mb-4">
                                <input
                                    type="checkbox"
                                    checked={preorder}
                                    onChange={() => setPreorder(!preorder)}
                                    className="form-checkbox h-5 w-5 text-blue-600"
                                />
                                <label
                                    className="ml-2 text-gray-700 text-md font-varela dark:text-white">Chci předběžně
                                    přihlásit mladší dítě</label>
                            </div>

                            <div className="flex items-center">
                                <input
                                    type="checkbox"
                                    checked={withAnother}
                                    onChange={() => setWithAnother(!withAnother)}
                                    className="form-checkbox h-5 w-5 text-blue-600 "
                                />
                                <label
                                    className="ml-2 text-gray-700 text-md font-varela dark:text-white">Chci být na lekcí s někým známým</label>
                            </div>
                            {withAnother && (
                                <div className="mt-4">
                                    <input
                                        type="text"
                                        value={formData.anotherName}
                                        onChange={handleChange('anotherName')}
                                        className="w-full px-4 py-2 font-varela border rounded-md text-gray-900 leading-tight focus:outline-none focus:shadow-outline dark:bg-gray-900 dark:text-white dark:border-gray-900"
                                        placeholder="Jméno známého dítěte"
                                    />
                                    <p className="text-gray-500 dark:text-gray-400 mt-2 italic font-varela text-sm">
                                        Poznámka: Za předpokladu volné kapacity na lekci. Budeme Vás kontaktovat pro upřesnění.
                                    </p>
                                </div>

                            )}
                        </div>
                    </div>

                )}

                {step === 4 && (
                    <div className="flex flex-col gap-4">
                        <div className="p-2 sm:p-6 bg-white rounded-lg shadow-md dark:bg-gray-700">
                            <div className="grid grid-cols-1 md:grid-cols-1 xl:grid-cols-2 gap-4 font-varela">
                                <div>
                                    <p><strong className="text-blue-900 text-xl dark:text-blue-400 underline">Kontaktní
                                        údaje</strong></p>
                                    <p className="dark:text-white text-gray-500"><strong
                                        className="dark:text-white text-black">Jméno:</strong> {formData.name}</p>
                                    <p className="dark:text-white text-gray-500"><strong
                                        className="dark:text-white text-black">Příjmení:</strong> {formData.surname}</p>
                                    <p className="dark:text-white text-gray-500"><strong
                                        className="dark:text-white text-black">Email:</strong> {formData.email}</p>
                                    <p className="dark:text-white text-gray-500"><strong
                                        className="dark:text-white text-black">Telefonní
                                        číslo:</strong> {formData.phone}
                                    </p>
                                </div>
                                <div>
                                    <p className="dark:text-white"><strong
                                        className="text-blue-900 text-xl dark:text-blue-400 underline">Zvolený
                                        kurz</strong>
                                    </p>
                                    <p className="dark:text-white text-gray-500"><strong
                                        className="dark:text-white text-black">Typ kurzu:</strong> {formData.courseType}
                                    </p>
                                    <p className="dark:text-white text-gray-500"><strong
                                        className="dark:text-white text-black">Lekce:</strong> {formData.specificCourse}
                                    </p>
                                    <p className="dark:text-white text-gray-500"><strong
                                        className="dark:text-white text-black">Místo:</strong> {formData.location}</p>
                                    {formData.specificCourse !== "Vaničkové plavání" && (
                                        <>
                                            <p className="dark:text-white text-gray-500"><strong
                                                className="dark:text-white text-black">Den:</strong> {formData.day}</p>
                                            <p className="dark:text-white text-gray-500"><strong
                                                className="dark:text-white text-black">Čas:</strong> {formData.time}</p>
                                        </>
                                    )}
                                    <p className="dark:text-white text-gray-500">
                                        <strong className="dark:text-white text-black">Cena: </strong>
                                        {formData.specificCourse === "Vaničkové plavání" ?
                                            (
                                                (locationsAndTimes[formData.courseType][formData.specificCourse].locations[formData.location]['dle domluvy']?.price === 3600 ||
                                                    locationsAndTimes[formData.courseType][formData.specificCourse].locations[formData.location]['dle domluvy']?.price === 3900 ||
                                                    locationsAndTimes[formData.courseType][formData.specificCourse].locations[formData.location]['dle domluvy']?.price === 2200) ?
                                                    (locationsAndTimes[formData.courseType][formData.specificCourse].locations[formData.location]['dle domluvy']?.price + ' Kč / 8 lekcí') :
                                                    (locationsAndTimes[formData.courseType][formData.specificCourse].locations[formData.location]['dle domluvy']?.price || 'Cena není k dispozici') + ' / lekce'
                                            ) :
                                            (
                                                (locationsAndTimes[formData.courseType][formData.specificCourse].locations[formData.location]?.[formData.day]?.price === 3600 ||
                                                    locationsAndTimes[formData.courseType][formData.specificCourse].locations[formData.location][formData.day]?.price === 3900 ||
                                                    locationsAndTimes[formData.courseType][formData.specificCourse].locations[formData.location]?.[formData.day]?.price === 2200) ?
                                                    (locationsAndTimes[formData.courseType][formData.specificCourse].locations[formData.location]?.[formData.day]?.price + ' Kč / 8 lekcí') :
                                                    (locationsAndTimes[formData.courseType][formData.specificCourse].locations[formData.location]?.[formData.day]?.price || 'Cena není k dispozici') + ' / lekce'
                                            )
                                        }
                                    </p>

                                </div>
                                <div>
                                    <p className="dark:text-white"><strong
                                        className="text-blue-900 text-xl dark:text-blue-400 underline">Údaje o
                                        Dítěti</strong></p>
                                    <p className="dark:text-white text-gray-500"><strong
                                        className="dark:text-white text-black">Jméno
                                        dítěte:</strong> {formData.childName}
                                    </p>
                                    <p className="dark:text-white text-gray-500"><strong
                                        className="dark:text-white text-black">Příjmení
                                        dítěte:</strong> {formData.childSurname}</p>
                                    <p className="dark:text-white text-gray-500"><strong
                                        className="dark:text-white text-black">Datum
                                        narození:</strong> {formData.childBirthdate.toLocaleDateString()}</p>
                                    {/*<p className="dark:text-white text-gray-500"><strong
                                        className="dark:text-white text-black">Pojišťovna:</strong> {formData.insurance.label} ({formData.insurance.code})
                                    </p>*/}
                                    <p className="dark:text-white text-gray-500"><strong
                                        className="dark:text-white text-black">Jiný kurz v
                                        minulosti:</strong> {isSenior ? "Ano" : "Ne"}
                                    </p>
                                    <p className="dark:text-white text-gray-500"><strong
                                        className="dark:text-white text-black">Předobjednání:</strong> {preorder ? "Ano" : "Ne"}
                                    </p>
                                    <p className="dark:text-white text-gray-500"><strong
                                        className="dark:text-white text-black">Být s někým známým:</strong> {withAnother ? `Ano - ${formData.anotherName}` : `Ne`}
                                    </p>
                                </div>
                                <div>
                                    <p><strong className="text-blue-900 text-xl dark:text-blue-400 underline">Zdravotní
                                        stav
                                        dítěte</strong></p>
                                    <p className="text-gray-500 dark:text-white">{formData.healthStatus}</p>
                                </div>
                            </div>

                    </div>
                        <div className="col-span-1 md:col-span-2 pl-2 pt-3">
                            <input
                                type="checkbox"
                                checked={formData.consent}
                                onChange={(e) => setFormData({...formData, consent: e.target.checked})}
                                className="mr-2"
                            />
                            <label className="dark:text-white text-gray-500 font-varela">Souhlasím se <span
                                className="text-blue-600 cursor-pointer"
                                onClick={() => setShowModal(true)}>zpracováním osobních údajů</span> a <span
                                className="text-blue-600 cursor-pointer"
                                onClick={() => formData.courseType === "LittleSwim" ? openSwimPdf() : openNinjasPdf()}>základními pravidly kurzu</span>
                            </label>
                            {errors.consent &&
                                <p className="text-red-500 text-sm mt-1 font-varela">{errors.consent}</p>}
                        </div>
                        {/*<SpringModal isOpen={showModal} setIsOpen={setShowModal}/>*/}

                        <DragCloseDrawer open={showModal} setOpen={setShowModal}>
                            <div className="mx-auto max-w-2xl space-y-4 text-neutral-200">
                                <h2 className="text-4xl font-bold text-white mb-4 text-center">
                                    Souhlas se zpracováním osobních údajů
                                </h2>
                                Ctíme soukromí Vás a Vašich dětí. Proto Vás zdvořile žádáme o poskytnutí souhlasu pouze s tím
                                nejdůležitějším pro interní účely, a to jméno, příjmení, adresa trvalého pobytu, telefon, email zákonného
                                zástupce dítěte, a to za následujících pravidel:
                                <br /><br />
                                1. Vaše osobní údaje včetně osobních údajů Vašeho dítěte zpracovávané za tímto účelem nebudou
                                předávány žádnému dalšímu příjemci.
                                <br /><br />
                                2. Svůj souhlas můžete kdykoliv odvolat, tím však není dotčena zákonnost zpracování vycházejícího
                                ze souhlasu, který byl dán před jeho odvoláním.
                                <br /><br />
                                3. Máte právo požadovat od správce přístup ke svým osobním údajům, jejich opravu nebo výmaz,
                                popř. omezení zpracování a právo na přenositelnost údajů. Vaše požadavky budou vždy řádně
                                posouzeny a vypořádány v souladu s příslušnými ustanoveními obecného nařízení o ochraně
                                osobních údajů a souvisejících právních předpisů.
                                <br /><br />
                                4. Máte případně právo podat stížnost u dozorového úřadu.
                                <br /><br />
                                5. V případě, že by správce hodlal Vaše osobní údaje zpracovávat pro jiný účel, než pro který byly
                                shromážděny, budete o tomto jiném účelu i dalších souvisejících skutečnostech vždy předem
                                informování a dále bude postupováno v souladu s platnou legislativou.
                                <br /><br />
                                6. Svá práva uvedená v této části můžete uplatňovat následujícími způsoby: osobně na pracovišti
                                správce po ověření totožnosti; e-mailem správci nebo pověřenci, kde musí být elektronický podpis
                                opatřený kvalifikovaným certifikátem.
                                <br /><br />
                                Podpisem přihlášky potvrzuji, že jsem se seznámil/a se zásadami zpracování osobních údajů a
                                organizačními a jinými pravidly, které jsou nedílnou součástí této přihlášky a se kterými plně souhlasím.
                                <br /><br />
                                Pořizování fotek a videí: Na lekci se budou pořizovat fotografie a videa Vašich dětí, a to pro účely
                                rodinného alba. Přirozeně se na videu či fotografii objeví rovněž rodič. Věříme, že s tímto nebudete mít
                                problém, a proto podpisem přihlášky s tímto plně vyjadřujete souhlas.
                                <br /><br />
                                Marketingové účely: budeme-li chtít sdílet něco na naše sociální sítě, kde by se mělo na fotografii nebo
                                videu Vaše dítě objevit, vždy to bude po individuální domluvě s rodičem.

                            </div>
                        </DragCloseDrawer>

                        <div className="flex justify-left items-center mt-1">
                            <ReCAPTCHA
                                className="invisible"
                                sitekey="6Ldakh4qAAAAAMEVmxj6EXh-T4dqAARql8SR6vDr"
                                ref={captchaRef}
                                onChange={handleCaptchaChange}
                                size="invisible"
                            />
                        </div>
                    </div>


                )}

                {step === 5 && (

                    <PaymentQRcode
                            course={formData.courseType}
                            amount={formData.specificCourse === "Vaničkové plavání" ? locationsAndTimes[formData.courseType][formData.specificCourse].locations[formData.location]['dle domluvy']?.price : locationsAndTimes[formData.courseType][formData.specificCourse].locations[formData.location]?.[formData.day]?.price}
                            variableSymbol={vsNumber}
                            message={formData.email}
                    />

                )}

                <div className="flex flex-col gap-2 mt-8">
                    {/* Zpět tlačítko */}
                    {step > 1  && step < 5  &&(
                        <button
                            type="button"
                            onClick={prevStep}
                            className="bg-gray-400 hover:bg-gray-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
                        >
                            Zpět
                        </button>
                    )}
                    {/* Další tlačítko */}
                    {step < 4 && (
                        <button
                            type="button"
                            onClick={nextStep}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
                        >
                            Další
                        </button>
                    )}
                    {/* Odeslat tlačítko */}
                    {step === 4 && (
                        /*<button
                            type="submit"
                            onClick={handleSubmit}
                            disabled={loading}
                            className="bg-amber-500 hover:bg-amber-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full text-lg"
                        >
                            {loading ? 'Odesílám...' : 'Závazně odeslat'}
                        </button>*/

                        <button
                        type="submit"
                        onClick={handleSubmit}
                        disabled={submitDisabled}
                        className="bg-amber-500 hover:bg-amber-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full text-lg flex items-center justify-center"
                        >
                            {submitText}
                        </button>
                )}
                </div>


            </form>
        </div>
    );
};

export default MultiStepForm;